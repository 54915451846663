// @flow
import React from 'react';
import StringUtil from '../../utils/StringUtil';
import { POPULAR_BANKS_VIEW } from './BankModalView';
import type { Bank } from '../../models/banks/Bank';

type Props = {
  banks: Array<Bank>,
  onTransition: (view: number) => void,
  onChangeBank: (bank: Bank) => void,
  onCancel: () => void,
};

type State = {
  banks: Array<Bank>,
  keyword: string,
};

export default class SelectBankView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      banks: [],
      keyword: '',
    };
  }

  onChangeKeyword(event: any) {
    let keyword = event.target.value;

    if (keyword !== '') {
      this.setState({
        banks: this.props.banks.filter(b => {
          return (
            b.name.indexOf(keyword) != -1 ||
            b.name.indexOf(StringUtil.convertHalfToFull(keyword)) != -1 ||
            b.kana.indexOf(StringUtil.convertJaSmallToLarge(keyword)) != -1 ||
            b.hiragana.indexOf(StringUtil.convertJaSmallToLarge(keyword)) != -1
          );
        }),
        keyword: keyword,
      });
    } else {
      this.setState({
        banks: [],
        keyword: keyword,
      });
    }
  }

  onKeyPress(event: any) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title w-100">金融機関を選択</h5>
            <button
              type="button"
              className="close"
              onClick={() => this.props.onCancel()}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body p-40">
            <form>
              <div className="form-group">
                <label>金融機関を検索</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="金融機関名を入力"
                  value={this.state.keyword}
                  onChange={e => this.onChangeKeyword(e)}
                  onKeyPress={e => this.onKeyPress(e)}
                />
              </div>
            </form>
            <div className="row mb-30 bank-select">
              {this.state.banks.map(b => {
                return (
                  <div className="col-md-4 mb-20" key={b.id}>
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-flat btn-outline-primary"
                      onClick={() => this.props.onChangeBank(b)}
                    >
                      {b.name}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="btn btn-lg back-btn btn-outline-primary"
              onClick={() => this.props.onTransition(POPULAR_BANKS_VIEW)}
            >
              戻る
            </button>
          </div>
        </div>
      </div>
    );
  }
}
