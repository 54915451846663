// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

declare var $: any;

export default class AgreementFormView extends JQueryView {
  edit_mode: boolean;
  constructor(edit_mode: boolean) {
    super('#user_individual_agreement_form');

    this.edit_mode = edit_mode;

    this.disabledNewCheckbox();
    this.scrollfunc();
  }

  @on('change .js-agreements-term')
  onChangeToggleAgreement(e: any) {
    const checked = $(e.currentTarget).prop('checked');

    $('#user_investor_agreement_attributes_pdf1').prop('checked', checked);
    $('#user_investor_agreement_attributes_pdf2').prop('checked', checked);
    $('#user_investor_agreement_attributes_pdf3').prop('checked', checked);
    $('#user_investor_agreement_attributes_pdf5').prop('checked', checked);

    this.cleanErrorMsg(e);
  }

  @on('change .js-agreements-policy')
  onChangeTogglePrivacyPolicy(e: any) {
    this.cleanErrorMsg(e);
  }

  @on('change .js-agreements-peps')
  onChangeTogglePeps(e: any) {
    const checked = $(e.currentTarget).prop('checked');

    $('#user_investor_pep_attributes_country').prop('checked', checked);
    $('#user_investor_pep_attributes_japanese_permanent_at_registration').prop(
      'checked',
      checked,
    );
    $('#user_investor_pep_attributes_fatca').prop('checked', checked);
    $('#user_investor_pep_attributes_peps').prop('checked', checked);

    this.cleanErrorMsg(e);
  }

  cleanErrorMsg(e: any) {
    const error = e.currentTarget.closest('.js-agreement-group.is-invalid');

    // 同意チェック時に、エラーメッセージが表示されていたら消す
    if ($(e.currentTarget).prop('checked') && error) {
      $(error).removeClass('is-invalid');
      $(error).addClass('is-active');

      $(error)
        .children('.agreements-checkbox-error')
        .remove();
    }
  }

  disabledNewCheckbox() {
    const $term = $('#agreements-term');
    const $policy = $('#agreements-policy');

    if ($term.closest('.js-agreement-group').hasClass('is-invalid')) {
      // エラーならそのまま
    } else if (!this.edit_mode && !$term.prop('checked')) {
      // 初期表示モードかつ未同意ならばチェックをを非活性にする
      $term.prop('disabled', true);
      $term.closest('.js-agreement-group').addClass('is-inactive');
    } else {
      $('#agreements-term')
        .closest('.js-agreement-group')
        .addClass('is-active');
    }

    if ($policy.closest('.js-agreement-group').hasClass('is-invalid')) {
      // エラーならそのまま
    } else if (!this.edit_mode && !$policy.prop('checked')) {
      $policy.prop('disabled', true);
      $policy.closest('.js-agreement-group').addClass('is-inactive');
    } else {
      $policy.closest('.js-agreement-group').addClass('is-active');
    }
  }

  activateDocAndCheckbox(el: any) {
    const $baseNode = $(el).closest('.js-agreement-group');
    if (!$baseNode) return;
    if ($baseNode.hasClass('is-active')) return;
    if ($baseNode.hasClass('is-invalid')) return;

    $baseNode.removeClass('is-inactive');
    $baseNode.addClass('is-active');
    $baseNode.find('.js-agreements-checkbox').prop('disabled', false);
  }

  scrollfunc() {
    // スクロールはバブリングで発火しないので個々バインドする
    $('.js-agreements-document').on('scroll', e => {
      this.activateDocAndCheckbox(e.currentTarget);
    });
  }

  @on('click .js-modal-open1')
  onClickOpenModal1() {
    $('.js-modal1').addClass('is-active');
  }

  @on('click .js-modal1')
  onClickCloaseModal1(e: any) {
    if (e.target == $('.js-modal1')[0]) {
      $('.js-modal1').removeClass('is-active');
    }
  }

  @on('click .js-modal-open2')
  onClickOpenModal2() {
    $('.js-modal2').addClass('is-active');
  }

  @on('click .js-modal2')
  onClickCloaseModal2(e: any) {
    if (e.target == $('.js-modal2')[0]) {
      $('.js-modal2').removeClass('is-active');
    }
  }

  @on('click .js-modal-open3')
  onClickOpenModal3() {
    $('.js-modal3').addClass('is-active');
  }

  @on('click .js-modal3')
  onClickCloaseModal3(e: any) {
    if (e.target == $('.js-modal3')[0]) {
      $('.js-modal3').removeClass('is-active');
    }
  }
}
