// @flow
export const KYC_TYPES = {
  oneDocument: 0,
  twoDocument: 1,
  useEkyc: 2,
};

export const IDENTIFICATION_TYPES = {
  driverLicense: 0,
  passport: 1,
  residentRegister: 2,
  myNumber: 3,
  others: 4,
  insurance: 5,
  residenceCertificate: 6,
  sealRegistration: 7,
};
