// @flow
import React from 'react';
import BankModalView from '../../../../banks/BankModalView';
import BankViewBase from '../../BankView';

import type { Bank } from '../../../../../models/banks/Bank';
import type { BankBranch } from '../../../../../models/banks/BankBranch';

type Props = {
  bank: ?Bank,
  branch: ?BankBranch,
  errors: any,
};

export default class BankView extends BankViewBase {
  hideError: boolean = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      bank: props.bank,
      branch: props.branch,
    };
  }

  onOk() {
    super.onOk && super.onOk();

    // エラーを非表示 の処理を追加
    this.hideError = true;
    // 再レンダリング
    this.setState({});
  }

  hasError() {
    if (this.hideError) return false;

    return super.hasError && super.hasError();
  }

  render() {
    const { bank, branch } = this.state;

    return (
      <div>
        <div
          className={
            this.hasError()
              ? 'customer-info-group customer-info-bank has-error'
              : 'customer-info-group customer-info-bank '
          }
        >
          <div className="col-form-label">
            <label className="col-form-label-ttl">金融機関・支店</label>
          </div>

          <div className="form-group-inner">
            <div className="customer-info-group-input input-bank">
              {(bank != null || branch != null) && (
                <div className="customer-info-group-bank">
                  {bank != null && <span className="mr-10">{bank.name}</span>}
                  {branch != null && <span>{branch.name}</span>}
                </div>
              )}
              <button
                type="button"
                className={
                  'btn btn-lg btn-flat btn-primary-outline-gray ' +
                  (bank && branch ? 'bank-btn-checked' : '')
                }
                data-toggle="modal"
                data-target="#bank_modal"
              >
                {bank && branch ? '再選択' : '金融機関・支店を選択'}
              </button>
              <input
                value={bank != null ? bank.id : ''}
                type="hidden"
                name="user[bank_id]"
              />
              <input
                value={branch != null ? branch.id : ''}
                type="hidden"
                name="user[bank_branch_id]"
              />
            </div>
            {this.hasError() && (
              <label className="validation-error error">
                金融機関・支店を選択してください
              </label>
            )}
          </div>
        </div>

        <BankModalView
          bank={bank}
          branch={branch}
          onChangeBank={bank => this.setState({ bank: bank })}
          onChangeBranch={branch => this.setState({ branch: branch })}
          onOk={() => this.onOk()}
          onCancel={() => this.onCancel()}
        />
      </div>
    );
  }
}
